// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report .date-picker {
  width: 150px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  padding: 6px 12px;
  font-size: 14px;
  margin-top: 10px;
}
.report .select-box {
  width: 200px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  padding: 6px 12px;
  font-size: 14px;
}
.report .select-box .select__control {
  background-color: white;
  border: none;
  box-shadow: none;
  outline: none;
}
.report .select-box .select__single-value {
  color: black;
}
.report .select-box .select__indicator-separator {
  display: none;
}
.report .select-box .select__menu {
  border-radius: 4px;
  margin-top: 4px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
}
.report .select-box .select__option--is-focused {
  background-color: #f8f9fa;
}
.report .select-box .select__option--is-selected {
  background-color: #f8f9fa;
}`, "",{"version":3,"sources":["webpack://./src/pages/reportPage/reportpage.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAGE;EACE,YAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,iBAAA;EACA,eAAA;AADJ;AAIE;EACE,uBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;AAFJ;AAKE;EACE,YAAA;AAHJ;AAME;EACE,aAAA;AAJJ;AAOE;EACE,kBAAA;EACA,eAAA;EACA,8CAAA;AALJ;AAQE;EACE,yBAAA;AANJ;AASE;EACE,yBAAA;AAPJ","sourcesContent":[".report {\n  .date-picker {\n    width: 150px;\n    height: 35px;\n    border-radius: 4px;\n    border: 1px solid #ced4da;\n    padding: 6px 12px;\n    font-size: 14px;\n    margin-top: 10px;\n  }\n\n  .select-box {\n    width: 200px;\n    height: 35px;\n    border-radius: 4px;\n    border: 1px solid #ced4da;\n    padding: 6px 12px;\n    font-size: 14px;\n  }\n\n  .select-box .select__control {\n    background-color: white;\n    border: none;\n    box-shadow: none;\n    outline: none;\n  }\n\n  .select-box .select__single-value {\n    color: black;\n  }\n\n  .select-box .select__indicator-separator {\n    display: none;\n  }\n\n  .select-box .select__menu {\n    border-radius: 4px;\n    margin-top: 4px;\n    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);\n  }\n\n  .select-box .select__option--is-focused {\n    background-color: #f8f9fa;\n  }\n\n  .select-box .select__option--is-selected {\n    background-color: #f8f9fa;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
