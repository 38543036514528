// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/logistic.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: darken;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-form {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  margin-top: 20px;
  width: 100%;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
}

.login-form button {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #45a049;
}`, "",{"version":3,"sources":["webpack://./src/pages/loginPage/loginpage.scss"],"names":[],"mappings":"AAAA;EACE,yDAAA;EACA,sBAAA;EACA,oCAAA;EACA,6BAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACF;;AAEA;EACE,0CAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":[".login-container {\r\n  background-image: url(\"../../assets/images/logistic.jpg\");\r\n  background-size: cover;\r\n  background-color: rgba(0, 0, 0, 0.6);\r\n  background-blend-mode: darken;\r\n  height: 100vh;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  flex-direction: column;\r\n}\r\n\r\n.login-form {\r\n  background-color: rgba(255, 255, 255, 0.8);\r\n  padding: 20px;\r\n  border-radius: 10px;\r\n  max-width: 400px;\r\n  margin-top: 20px;\r\n  width: 100%;\r\n}\r\n\r\n.login-form h2 {\r\n  text-align: center;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.login-form input {\r\n  width: 100%;\r\n  padding: 10px;\r\n  margin-bottom: 10px;\r\n  border-radius: 5px;\r\n  border: none;\r\n}\r\n\r\n.login-form button {\r\n  width: 100%;\r\n  padding: 10px;\r\n  border-radius: 5px;\r\n  border: none;\r\n  background-color: #4caf50;\r\n  color: white;\r\n  cursor: pointer;\r\n}\r\n\r\n.login-form button:hover {\r\n  background-color: #45a049;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
