import React, { useState } from "react";
import "./loginpage.scss";
import { useEffect } from "react";
import Services from "../../services/Services";
import logo from "../../assets/images/malllogisticslogo.png";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);

  function handleLogin() {
    if (email != "" && password != "") {
      setLoading(true);
      Services.Login(token, email, password)
        .then((res) => {
          console.log(res.data);
          if (res.data.status) {
            setLoading(false);
            localStorage.setItem("loginID", res.data.result.id);
            localStorage.setItem("poi", res.data.result.poi[0]);
            localStorage.setItem("mallID", res.data.result.mallID);
            navigate("/homepage");
          } else {
            if (res.data.err == "Password not matches") {
              alert("Parola eşleşmiyor.");
              setLoading(false);
            } else {
              setLoading(false);
              alert("Kullanıcı bulunamadı.");
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      setLoading(false);
      alert("Bilgileri Giriniz.");
    }
  }

  useEffect(() => {
    Services.GetToken()
      .then((res) => setToken(res.data.result.token))
      .catch((err) => alert("Bağlantı Hatası"));
  }, []);

  return (
    <div className="login-container">
      <img src={logo} alt="Logo" style={{ width: "15%", minWidth: 150 }} />
      <div className="login-form">
        <h2>Giriş Yap</h2>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-posta"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Şifre"
        />
        <button onClick={handleLogin}>
          {loading ? (
            <>
              <ClipLoader color={"white"} loading={loading} size={25} />
            </>
          ) : (
            "Giriş"
          )}
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
