import Header from "../../components/Header";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Services from "../../services/Services";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const shipmentStatuses = [
  { label: "Robottan Onay Bekleniyor", value: "26" },
  { label: "Robota Teslim Edilecek", value: "27" },
  { label: "Robot Üzerinde", value: "28" },
  { label: "Robot Üzerinden İptal", value: "29" },
];

function RobotPage() {
  const navigate = useNavigate();
  const mallID = localStorage.getItem("mallID");
  const poiID = localStorage.getItem("poi");
  const [isEmpty, setIsEmpty] = useState(false);
  const [token, setToken] = useState("");
  const [orders, setOrders] = useState([]);
  const [points, setPoints] = useState([]);
  const [corporationalList, setCorporationalList] = useState([]);
  const [list26, setList26] = useState([]);
  const [list27, setList27] = useState([]);
  const [list28, setList28] = useState([]);
  const [list29, setList29] = useState([]);

  const getStatusLabel = (statusValue) => {
    const status = shipmentStatuses.find((item) => item.value === statusValue);
    return status ? status.label : "";
  };

  const getTitleById = (id) => {
    const item = points.find((item) => item.id === id);
    return item ? item.title : "";
  };

  const formatDate = (date) => {
    const formattedDate = moment.unix(date).format("DD.MM.YYYY HH:mm");
    return formattedDate;
  };

  const getNameById = (id) => {
    const name = corporationalList.find((item) => item.id === id);
    return name ? name.title : "";
  };

  const changeStatus = (id) => {
    console.log(id);
    Services.ChangeStatusPullToBranch(token, id)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === true) {
          fetchDatas();
        } else {
          alert("Bir hata oluştu");
        }
      })
      .catch((err) => {
        alert("Bir hata oluştu");
        console.log(err);
      });
  };

  const deleteOrder = (id) => {
    console.log(id);
    Services.DeleteRobotOrder(token, id)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === true) {
          fetchDatas();
        } else {
          alert("Bir hata oluştu");
        }
      })
      .catch((err) => {
        alert("Bir hata oluştu");
        console.log(err);
      });
  };

  function fetchDatas() {
    Services.GetToken().then((res) => {
      localStorage.setItem("token", res.data.result.token);
      setToken(res.data.result.token);
      Services.GetLocations(res.data.result.token).then((result) => {
        setPoints(result.data.result);
      });
      Services.GetOrders(res.data.result.token, poiID, [26, 27, 28, 29]).then(
        (result) => {
          if (result.data.status == true) {
            setOrders(result.data.result);
            console.log("Güncel data:", result.data.result);
            setIsEmpty(false);
          } else {
            setIsEmpty(true);
          }
        }
      );
      Services.GetCorporationList(res.data.result.token, mallID)
        .then((result) => {
          setCorporationalList(result.data.result);
        })
        .catch((err) => console.log(err));
    });
  }

  useEffect(() => {
    const filteredList1 = orders.filter((item) => item.status === "26");
    const filteredList2 = orders.filter((item) => item.status === "27");
    const filteredList3 = orders.filter((item) => item.status === "28");
    const filteredList4 = orders.filter((item) => item.status === "29");

    setList26(filteredList1);
    setList27(filteredList2);
    setList28(filteredList3);
    setList29(filteredList4);
  }, [orders]);

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <div className="app">
      <Header />
      <div className="center">
        <div
          style={{
            flexDirection: "column",
            overflowX: "auto",
            minHeight: 700,
          }}
        >
          <h4 style={{ textAlign: "center" }}>Robottan Onay Bekleyenler</h4>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Kargo Takip No</th>
                  <th>Firma/Kişi</th>
                  <th>Nokta Adı</th>
                  <th>Kargo Durumu</th>
                  <th>Oluşturulma Tarihi</th>
                  <th>İşlem</th>
                </tr>
              </thead>
              <tbody>
                {list26.length === 0 ? (
                  <tr>
                    <td colSpan="7" style={{ textAlign: "center" }}>
                      <h5>Gönderi bulunmamaktadır</h5>
                    </td>
                  </tr>
                ) : (
                  <>
                    {list26.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.shipmentNo}</td>
                        <td>{getNameById(item.uID)}</td>
                        <td>{getTitleById(item.sentToEasyPoint)}</td>
                        <td>{getStatusLabel(item.status)}</td>
                        <td>{formatDate(item.createdDateTime)}</td>
                        <td>
                          <FaEye
                            onClick={() => {
                              navigate("/detail", {
                                state: {
                                  title: item.title,
                                  id: item.id,
                                  corporationTitle: getNameById(item.uID),
                                  postType: item.postType,
                                  takenName: item.takenName,
                                  willTakenBy: item.willTakenBy,
                                  phone: item.phone,
                                  poi: getTitleById(item.sentToEasyPoint),
                                  shipmentFirm: item.shipmentFirm,
                                  city: item.sentToCity,
                                  region: item.sentToRegion,
                                  cargo: item.shipmentNo,
                                  postDate: item.createdDateTime,
                                  deliverDateTime: item.deliverDateTime,
                                  payment: item.payment,
                                  status: item.status,
                                },
                              });
                            }}
                            style={{
                              color: "black",
                              fontSize: "22px",
                              marginLeft: 7,
                              cursor: "pointer",
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            style={{
                              backgroundColor: "rgb(245, 97, 97)",
                              border: "none",
                            }}
                            onClick={() => deleteOrder(item.id)}
                          >
                            <text style={{ fontWeight: "600" }}>İptal Et</text>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>

          <h4 style={{ textAlign: "center" }}>Robota Teslim Edilecekler</h4>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Kargo Takip No</th>
                  <th>Firma/Kişi</th>
                  <th>Nokta Adı</th>
                  <th>Kargo Durumu</th>
                  <th>Oluşturulma Tarihi</th>
                  <th>İşlem</th>
                </tr>
              </thead>
              <tbody>
                {list27.length === 0 ? (
                  <tr>
                    <td colSpan="7" style={{ textAlign: "center" }}>
                      <h5>Gönderi bulunmamaktadır</h5>
                    </td>
                  </tr>
                ) : (
                  <>
                    {list27.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.shipmentNo}</td>
                        <td>{getNameById(item.uID)}</td>
                        <td>{getTitleById(item.sentToEasyPoint)}</td>
                        <td>{getStatusLabel(item.status)}</td>
                        <td>{formatDate(item.createdDateTime)}</td>
                        <td>
                          <FaEye
                            onClick={() => {
                              navigate("/detail", {
                                state: {
                                  title: item.title,
                                  id: item.id,
                                  corporationTitle: getNameById(item.uID),
                                  postType: item.postType,
                                  takenName: item.takenName,
                                  willTakenBy: item.willTakenBy,
                                  phone: item.phone,
                                  poi: getTitleById(item.sentToEasyPoint),
                                  shipmentFirm: item.shipmentFirm,
                                  city: item.sentToCity,
                                  region: item.sentToRegion,
                                  cargo: item.shipmentNo,
                                  postDate: item.createdDateTime,
                                  deliverDateTime: item.deliverDateTime,
                                  payment: item.payment,
                                  status: item.status,
                                },
                              });
                            }}
                            style={{
                              color: "black",
                              fontSize: "22px",
                              marginLeft: 7,
                              cursor: "pointer",
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            style={{
                              backgroundColor: "rgb(245, 97, 97)",
                              border: "none",
                            }}
                            onClick={() => deleteOrder(item.id)}
                          >
                            <text style={{ fontWeight: "600" }}>İptal Et</text>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>

          <h4 style={{ textAlign: "center" }}>Robot Üzerinde Olanlar</h4>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Kargo Takip No</th>
                  <th>Firma/Kişi</th>
                  <th>Nokta Adı</th>
                  <th>Kargo Durumu</th>
                  <th>Oluşturulma Tarihi</th>
                  <th>İşlem</th>
                </tr>
              </thead>
              <tbody>
                {list28.length === 0 ? (
                  <tr>
                    <td colSpan="7" style={{ textAlign: "center" }}>
                      <h5>Gönderi bulunmamaktadır</h5>
                    </td>
                  </tr>
                ) : (
                  <>
                    {list28.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.shipmentNo}</td>
                        <td>{getNameById(item.uID)}</td>
                        <td>{getTitleById(item.sentToEasyPoint)}</td>
                        <td>{getStatusLabel(item.status)}</td>
                        <td>{formatDate(item.createdDateTime)}</td>
                        <td>
                          <FaEye
                            onClick={() => {
                              navigate("/detail", {
                                state: {
                                  title: item.title,
                                  id: item.id,
                                  corporationTitle: getNameById(item.uID),
                                  postType: item.postType,
                                  takenName: item.takenName,
                                  willTakenBy: item.willTakenBy,
                                  phone: item.phone,
                                  poi: getTitleById(item.sentToEasyPoint),
                                  shipmentFirm: item.shipmentFirm,
                                  city: item.sentToCity,
                                  region: item.sentToRegion,
                                  cargo: item.shipmentNo,
                                  postDate: item.createdDateTime,
                                  deliverDateTime: item.deliverDateTime,
                                  payment: item.payment,
                                  status: item.status,
                                },
                              });
                            }}
                            style={{
                              color: "black",
                              fontSize: "22px",
                              marginLeft: 7,
                              cursor: "pointer",
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            style={{
                              backgroundColor: "rgb(245, 97, 97)",
                              border: "none",
                            }}
                            onClick={() => deleteOrder(item.id)}
                          >
                            <text style={{ fontWeight: "600" }}>İptal Et</text>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>

          <h4 style={{ textAlign: "center" }}>Robot Üzerinden İptal Olanlar</h4>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Kargo Takip No</th>
                  <th>Firma/Kişi</th>
                  <th>Nokta Adı</th>
                  <th>Kargo Durumu</th>
                  <th>Oluşturulma Tarihi</th>
                  <th>İşlem</th>
                </tr>
              </thead>
              <tbody>
                {list29.length === 0 ? (
                  <tr>
                    <td colSpan="7" style={{ textAlign: "center" }}>
                      <h5>Gönderi bulunmamaktadır</h5>
                    </td>
                  </tr>
                ) : (
                  <>
                    {list29.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.shipmentNo}</td>
                        <td>{getNameById(item.uID)}</td>
                        <td>{getTitleById(item.sentToEasyPoint)}</td>
                        <td>{getStatusLabel(item.status)}</td>
                        <td>{formatDate(item.createdDateTime)}</td>
                        <td>
                          <FaEye
                            onClick={() => {
                              navigate("/detail", {
                                state: {
                                  title: item.title,
                                  id: item.id,
                                  corporationTitle: getNameById(item.uID),
                                  postType: item.postType,
                                  takenName: item.takenName,
                                  willTakenBy: item.willTakenBy,
                                  phone: item.phone,
                                  poi: getTitleById(item.sentToEasyPoint),
                                  shipmentFirm: item.shipmentFirm,
                                  city: item.sentToCity,
                                  region: item.sentToRegion,
                                  cargo: item.shipmentNo,
                                  postDate: item.createdDateTime,
                                  deliverDateTime: item.deliverDateTime,
                                  payment: item.payment,
                                  status: item.status,
                                },
                              });
                            }}
                            style={{
                              color: "black",
                              fontSize: "22px",
                              marginLeft: 7,
                              cursor: "pointer",
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            style={{
                              backgroundColor: "#3677d7",
                              border: "none",
                            }}
                            onClick={() => changeStatus(item.id)}
                          >
                            <text style={{ fontWeight: "600" }}>
                              Şubeye Teslim Et
                            </text>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RobotPage;
