import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import MassEntryPage from "./pages/massEntry/MassEntryPage";
import DetailPage from "./pages/detailPage/DetailPage";
import DeliveryFromCargo from "./pages/massEntry/DeliveryFromCargo";
import DeliveryFromPorter from "./pages/massEntry/DeliveryFromPorter";
import LoginPage from "./pages/loginPage/LoginPage";
import MassDelivery from "./pages/massDelivery/MassDelivery";
import ReportPage from "./pages/reportPage/ReportPage";
import Test from "./pages/Test";
import RobotPage from "./pages/robotPage/RobotPage";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/homepage" element={<HomePage />} />
        <Route path="/mass-delivery" element={<MassDelivery />} />
        <Route path="/mass-entry" element={<MassEntryPage />} />
        <Route
          path="/mass-entry-delivery-from-cargo"
          element={<DeliveryFromCargo />}
        />
        <Route
          path="/mass-entry-delivery-from-porter"
          element={<DeliveryFromPorter />}
        />
        <Route path="/detail" element={<DetailPage />} />
        <Route path="/robotpage" element={<RobotPage />} />
        <Route path="/reportpage" element={<ReportPage />} />
        <Route path="/test" element={<Test />} />
      </Routes>
    </>
  );
}

export default App;
