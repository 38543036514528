// import Header from "../../components/Header";
// import React, { useEffect, useState } from "react";
// import Select from "react-select";
// import { shipmentFirms } from "../../types/datas";
// import "./massentrypage.scss";
// import Services from "../../services/Services";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { useNavigate } from "react-router-dom";
// import ClipLoader from "react-spinners/ClipLoader";

// function MassEntryPage() {
//   const navigate = useNavigate();
//   const mallID = localStorage.getItem("mallID");
//   const [token, setToken] = useState("");
//   const [shipmentFirm, setShipmentFirm] = useState(null);
//   const [selectShipmentFirm, setSelectShipmentFirm] = useState(null);
//   const [corporationalList, setCorporationalList] = useState([]);
//   const [customerList, setCustomerList] = useState([]);
//   const [forms, setForms] = useState([
//     {
//       id: 1,
//       name: "Form 1",
//       formData: {
//         uID: "",
//         numberOfPackages: 1,
//         poiID: "86561942017699686248",
//         direction: "InBranch",
//         shipmentNo: "",
//         deliveryCode: "",
//         postType: "Paket",
//         corporation: "",
//         worker: "",
//         takenName: "",
//       },
//     },
//   ]);
//   const [loading, setLoading] = useState(false);

//   const handleShipmentFirmChange = (selectedOption) => {
//     setShipmentFirm(selectedOption.label);
//     setSelectShipmentFirm(selectedOption);
//     setForms((prevForms) => {
//       const updatedForms = [...prevForms];

//       updatedForms.forEach((form) => {
//         if (form.formData.shipmentFirm === null) {
//           form.formData.shipmentFirm = selectedOption.label;
//         }
//       });

//       return updatedForms;
//     });
//   };

//   const handleInputChange = (e, formId) => {
//     const { name, value } = e.target;
//     setForms((prevForms) => {
//       const updatedForms = [...prevForms];
//       const formIndex = updatedForms.findIndex((form) => form.id === formId);

//       if (formIndex !== -1) {
//         updatedForms[formIndex].formData[name] = value;
//         updatedForms[formIndex].formData.shipmentFirm = shipmentFirm;

//         if (name == "shipmentNo") {
//           updatedForms[formIndex].formData.deliveryCode = value;
//         } else if (name == "worker") {
//           const selectedCustomer = customerList.find(
//             (customer) => customer.id === value
//           );
//           if (selectedCustomer) {
//             updatedForms[formIndex].formData.customer = {
//               name: selectedCustomer.name,
//               lastname: selectedCustomer.lastname,
//               phone: selectedCustomer.gsm,
//             };
//           }
//           updatedForms[formIndex].formData.uID = value;
//         }
//       }

//       return updatedForms;
//     });
//   };

//   const handleSubmit = () => {
//     setLoading(true);
//     if (forms.length != 0) {
//       const formDataArray = forms.map((form) => {
//         const { corporation, worker, ...formData } = form.formData;
//         return formData;
//       });
//       Services.CreateOrder(token, formDataArray)
//         .then((res) => {
//           console.log(res.data);
//           setLoading(false);
//           toast.success("Toplu Kargo Alımı Tamamlandı", {
//             position: "top-center",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//           });
//           setForms([
//             {
//               id: 1,
//               name: "Form 1",
//               formData: {
//                 uID: "",
//                 numberOfPackages: 1,
//                 poiID: "86561942017699686248",
//                 direction: "InBranch",
//                 shipmentNo: "",
//                 deliveryCode: "",
//                 postType: "Paket",
//                 corporation: "",
//                 worker: "",
//                 takenName: "",
//               },
//             },
//           ]);
//         })
//         .catch((err) => {
//           console.log(err);
//           toast.error("Bir Hata Oluştu", {
//             position: "top-center",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//           });
//           setLoading(false);
//         });
//     } else {
//       setLoading(false);
//       return;
//     }
//   };

//   const addRow = () => {
//     const newFormId = forms.length + 1;
//     const newFormName = `Form ${newFormId}`;

//     const newForm = {
//       id: newFormId,
//       name: newFormName,
//       formData: {
//         uID: "",
//         numberOfPackages: 1,
//         poiID: "86561942017699686248",
//         direction: "InBranch",
//         shipmentNo: "",
//         deliveryCode: "",
//         postType: "Paket",
//         corporation: "",
//         worker: "",
//         takenName: "",
//       },
//     };

//     setForms([...forms, newForm]);
//   };

//   const deleteRow = (formId) => {
//     setForms((prevForms) => prevForms.filter((form) => form.id !== formId));
//   };

//   function fetchDatas() {
//     Services.GetToken().then((res) => {
//       localStorage.setItem("token", res.data.result.token);
//       setToken(res.data.result.token);
//       Services.GetCorporationList(res.data.result.token, mallID)
//         .then((result) => {
//           setCorporationalList(result.data.result);
//         })
//         .catch((err) => console.log(err));

//       Services.GetCustomerList(res.data.result.token)
//         .then((result) => {
//           setCustomerList(result.data.result);
//         })
//         .catch((err) => console.log(err));
//     });
//   }

//   useEffect(() => {
//     fetchDatas();
//   }, []);

//   return (
//     <div className="mass-entry">
//       <Header />
//       <div className="center">
//         <div
//           style={{
//             justifyContent: "flex-start",
//             display: "flex",
//             width: "85%",
//             flexDirection: "column",
//           }}
//         >
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               flexDirection: "row",
//               marginBottom: 50,
//             }}
//           >
//             <button onClick={() => navigate("/mass-entry-delivery-from-cargo")}>
//               Kargodan Teslim Alım
//             </button>
//             <button
//               onClick={() => navigate("/mass-entry-delivery-from-porter")}
//             >
//               Porterden Teslim Alım
//             </button>
//           </div>
//           <text>Lütfen Kargo Firmasını Seçin*</text>
//           <Select
//             placeholder="Kargo Firması"
//             className="basic-single"
//             classNamePrefix="select"
//             name="color"
//             value={selectShipmentFirm}
//             options={shipmentFirms}
//             onChange={handleShipmentFirmChange}
//           />
//         </div>
//       </div>
//       <div className="center">
//         <div
//           style={{
//             width: "100%",
//             display: "flex",
//             width: "85%",
//             justifyContent: "space-between",
//             flexDirection: "column",
//           }}
//           className="form-content"
//         >
//           {forms.map((form) => (
//             <div
//               className="form-content"
//               style={{
//                 flexDirection: "row",
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBlock: 30,
//               }}
//               key={form.id}
//             >
//               <div style={{ display: "flex", flexDirection: "column" }}>
//                 <text>Müşteri *</text>
//                 <select
//                   name="corporation"
//                   placeholder={`Müşteri`}
//                   value={form.formData.corporation}
//                   onChange={(e) => handleInputChange(e, form.id)}
//                 >
//                   <option value={""}>{""}</option>
//                   {corporationalList.map((item, i) => {
//                     return (
//                       <option key={i} value={item.title}>
//                         {item.title}
//                       </option>
//                     );
//                   })}
//                 </select>
//               </div>

//               <div style={{ display: "flex", flexDirection: "column" }}>
//                 <text>Çalışan/Oturan *</text>
//                 <select
//                   name="worker"
//                   placeholder={`Çalışan/Oturan`}
//                   value={form.formData.worker}
//                   onChange={(e) => handleInputChange(e, form.id)}
//                 >
//                   <option value={""}>{""}</option>
//                   {customerList.map((item, i) => {
//                     return (
//                       <option key={i} value={item.id}>
//                         {item.name} {item.lastname}
//                       </option>
//                     );
//                   })}
//                 </select>
//               </div>

//               <div style={{ display: "flex", flexDirection: "column" }}>
//                 <text>Kargo Takip No *</text>
//                 <input
//                   type="text"
//                   name="shipmentNo"
//                   placeholder={`Kargo Takip No`}
//                   value={form.formData.shipmentNo}
//                   onChange={(e) => handleInputChange(e, form.id)}
//                 />
//               </div>

//               {/* <div style={{ display: "flex", flexDirection: "column" }}>
//                 <text>Kargonuzun Türü *</text>
//                 <input
//                   type="text"
//                   name="postType"
//                   placeholder={`Kargonuzun Türü`}
//                   value={form.formData.postType}
//                   onChange={(e) => handleInputChange(e, form.id)}
//                 />
//               </div> */}

//               <div style={{ display: "flex", flexDirection: "column" }}>
//                 <text>Gönderen Kişi/Firma Adı</text>
//                 <input
//                   type="text"
//                   name="takenName"
//                   placeholder={`Gönderen Kişi/Firma Adı`}
//                   value={form.formData.takenName}
//                   onChange={(e) => handleInputChange(e, form.id)}
//                 />
//               </div>
//               <text
//                 className="button-71"
//                 style={{
//                   backgroundColor: "rgb(245, 97, 97)",
//                   height: 34,
//                   paddingInline: 15,
//                   alignItems: "center",
//                   display: "flex",
//                   color: "white",
//                   borderRadius: 5,
//                   fontWeight: "600",
//                   marginTop: 30,
//                 }}
//                 onClick={() => deleteRow(form.id)}
//               >
//                 Satırı Sil
//               </text>
//             </div>
//           ))}
//           <div
//             style={{
//               flexDirection: "row",
//               display: "flex",
//               justifyContent: "center",
//               paddingTop: 30,
//               paddingBottom: 100,
//             }}
//           >
//             <button className="button-71 me-5" onClick={addRow}>
//               Satır Ekle
//             </button>
//             <button
//               style={{ backgroundColor: "green" }}
//               className="button-71 "
//               onClick={handleSubmit}
//             >
//               {loading ? (
//                 <>
//                   <ClipLoader color={"white"} loading={loading} size={25} />
//                 </>
//               ) : (
//                 "Gönder"
//               )}
//             </button>
//             <ToastContainer
//               position="top-right"
//               autoClose={5000}
//               hideProgressBar={false}
//               newestOnTop={false}
//               closeOnClick
//               rtl={false}
//               pauseOnFocusLoss
//               draggable
//               pauseOnHover
//               theme="light"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default MassEntryPage;
