import axios from "axios";
import { BASE_URL, KEY, SECRET } from ".";

export default {
  GetToken() {
    return axios({
      method: "post",
      url: `${BASE_URL}/get-token`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        key: KEY,
        secret: SECRET,
      },
    });
  },

  Login(token, email, password) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/admin-login`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        email: `${email}`,
        password: `${password}`,
      },
    });
  },

  GetLocations(token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/easypoint-centers`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        muhaberat: true,
      },
    });
  },

  GetOrders(token, poiID, status) {
    console.log({
      data: {
        status: status,
        poi: `${poiID}`,
      },
    });
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/get-corporational-order-list`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        status: status,
        poi: `${poiID}`,
      },
    });
  },

  GetFilteredOrders(token, poiID, status, startDate, endDate) {
    console.log({
      data: {
        status: status,
        poi: `${poiID}`,
        startDate: `${startDate}`,
        endDate: `${endDate}`,
      },
    });
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/get-corporational-order-list`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        status: status,
        poi: `${poiID}`,
        startDate: `${startDate}`,
        endDate: `${endDate}`,
      },
    });
  },

  GetCorporationList(token, mallID) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/get-corporation-list`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        type: "all",
        mallID: `${mallID}`,
      },
    });
  },

  GetCustomerList(token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/get-customer-list`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        type: "all",
      },
    });
  },

  CreateOrder(token, posts) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/create-corporational-order`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        posts: posts,
      },
    });
  },

  SendSMS(phone, sms, token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/send-sms`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        phone: `${phone}`,
        sms: `${sms}`,
      },
    });
  },

  ExportToExcel(token, content, sheetTitle) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/export`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        type: `excel`,
        content: content,
        header: {
          ID: "number",
          "Kargo Takip No": "string",
          "Firma/Kişi": "string",
          "Nokta Adı": "string",
          "Kargo Durumu": "string",
          "Oluşturulma Tarihi": "string",
        },
        sheetTitle: `${sheetTitle}`,
      },
    });
  },

  ChangeStatusPullToBranch(token, id) {
    console.log({
      data: {
        id: `${id}`,
        status: 5,
      },
    });
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/change-status`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: `${id}`,
        status: 5,
      },
    });
  },

  ChangeStatus(token, id, deliveryType, deliveryValue, additionalNote) {
    console.log({
      data: {
        id: `${id}`,
        status: 1,
        deliveryType: `${deliveryType}`,
        deliveryValue: `${deliveryValue}`,
        additionalNote: `${additionalNote}`,
      },
    });
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/change-status`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: `${id}`,
        status: 1,
        deliveryType: `${deliveryType}`,
        deliveryValue: `${deliveryValue}`,
        additionalNote: `${additionalNote}`,
      },
    });
  },

  DeleteRobotOrder(token, id) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/delete-saharobotik-order`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        orderID: `${id}`,
      },
    });
  },

  GetRoboticCustomerList(token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/get-saharobotik-customer-list`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      // data: {},
    });
  },

  CreateRobotOrder(token, addressID, phone, senderName, orderID) {
    console.log({
      data: {
        addressID: `${addressID}`,
        phone: `${phone}`,
        senderName: `${senderName}`,
        orderID: `${orderID}`,
      },
    });
    return axios({
      method: "post",
      url: `${BASE_URL}/easypoint/create-saharobotik-order`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        addressID: `${addressID}`,
        phone: `${phone}`,
        senderName: `${senderName}`,
        orderID: `${orderID}`,
      },
    });
  },
  // CreateOrder(token) {
  //   return axios({
  //     method: "post",
  //     url: `${BASE_URL}/easypoint/create-corporational-order`,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json",
  //     },
  //     data: {
  //       posts: [
  //         {
  //           uID: "66229760983913794107",
  //           numberOfPackages: 1,
  //           poiID: "86561942017699686248",
  //           direction: "InBranch",
  //           shipmentFirm: "MNG Kargo",
  //           shipmentNo: "53555555555",
  //           deliveryCode: "53555555555",
  //           customer: {
  //             name: "Batuhan",
  //             lastname: "Göktaş",
  //             phone: "05350888328",
  //           },
  //         },
  //       ],
  //     },
  //   });
  // },
};
