import { useEffect, useState } from "react";
import Services from "../services/Services";
import Header from "../components/Header";
import SyncLoader from "react-spinners/SyncLoader";

function Test() {
  const [customers, setCustomers] = useState([]);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Services.GetCustomerList(token)
      .then((res) => {
        setCustomers(res.data.result);
        setLoading(false);
      })
      .catch(() => {
        alert("Veri çekerken hata oluştu");
        setLoading(false);
      });
  }, []);

  return (
    <div className="app">
      <Header />
      <div className="center" style={{ flexDirection: "column" }}>
        {loading ? (
          <div style={{ display: "flex", marginTop: 50 }}>
            <SyncLoader color={"blue"} loading={loading} size={10} />
          </div>
        ) : (
          <>
            {customers.length > 0 &&
              customers.map((item, i) => (
                <div key={i}>
                  <text>ID: {item.id} ---- </text>
                  <text>İsim: {item.name} ---- </text>
                  <text>Soyad: {item.lastname}</text>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
}

export default Test;
