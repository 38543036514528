import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/malllogisticslogo.png";
import "./header.scss";

function Header() {
  const navigate = useNavigate();
  return (
    <Navbar expand="lg">
      <Container style={{ maxWidth: "85%", marginBlock: 10 }} fluid>
        <Navbar.Brand
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/homepage")}
        >
          <img src={logo} style={{ height: 75 }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
          ></Nav>
          <Link className="header-link text-black nav-link" to="/homepage">
            Ana Sayfa
          </Link>
          <Link
            className="header-link text-black nav-link"
            to="/mass-entry-delivery-from-cargo"
          >
            Toplu Kargo Alımı
          </Link>
          <Link className="header-link text-black nav-link" to="/mass-delivery">
            Toplu Kargo Teslimi
          </Link>
          <Link className="header-link text-black nav-link" to="/robotpage">
            Robot Gönderileri
          </Link>
          <Link className="header-link text-black nav-link" to="/reportpage">
            Raporlar
          </Link>
          {/* <Link className="header-link text-black nav-link" to="/test">
            Test
          </Link> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
