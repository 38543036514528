const SET_HOMEPAGE_STARTDATE = "SET_HOMEPAGE_STARTDATE";
const SET_HOMEPAGE_ENDDATE = "SET_HOMEPAGE_ENDDATE";
const SET_HOMEPAGE_STATUS = "SET_HOMEPAGE_STATUS";
const SET_HOMEPAGE_SELECTSTATUS = "SET_HOMEPAGE_SELECTSTATUS";

const SET_REPORTPAGE_STARTDATE = "SET_REPORTPAGE_STARTDATE";
const SET_REPORTPAGE_ENDDATE = "SET_REPORTPAGE_ENDDATE";
const SET_REPORTPAGE_STATUS = "SET_REPORTPAGE_STATUS";
const SET_REPORTPAGE_SELECTSTATUS = "SET_REPORTPAGE_SELECTSTATUS";

export const setHomeStartDate = (home_startdate) => ({
  type: SET_HOMEPAGE_STARTDATE,
  payload: home_startdate,
});
export const setHomeEndDate = (home_enddate) => ({
  type: SET_HOMEPAGE_ENDDATE,
  payload: home_enddate,
});
export const setHomeStatus = (home_status) => ({
  type: SET_HOMEPAGE_STATUS,
  payload: home_status,
});
export const setHomeSelectStatus = (home_select_status) => ({
  type: SET_HOMEPAGE_SELECTSTATUS,
  payload: home_select_status,
});

export const setReportStartDate = (report_startdate) => ({
  type: SET_REPORTPAGE_STARTDATE,
  payload: report_startdate,
});
export const setReportEndDate = (report_enddate) => ({
  type: SET_REPORTPAGE_ENDDATE,
  payload: report_enddate,
});
export const setReportStatus = (report_status) => ({
  type: SET_REPORTPAGE_STATUS,
  payload: report_status,
});
export const setReportSelectStatus = (report_select_status) => ({
  type: SET_REPORTPAGE_SELECTSTATUS,
  payload: report_select_status,
});

const initialState = {
  home_startdate: new Date(),
  home_enddate: new Date(),
  home_status: [5, 31],
  home_select_status: { label: "Tümü", value: "100" },
  report_startdate: new Date(),
  report_enddate: new Date(),
  report_status: [5, 1, 31],
  report_select_status: { label: "Tümü", value: "100" },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_HOMEPAGE_STARTDATE:
      console.log({ ...state, home_startdate: payload });
      return { ...state, home_startdate: payload };
    case SET_HOMEPAGE_ENDDATE:
      console.log({ ...state, home_enddate: payload });
      return { ...state, home_enddate: payload };
    case SET_HOMEPAGE_STATUS:
      console.log({ ...state, home_status: payload });
      return { ...state, home_status: payload };
    case SET_HOMEPAGE_SELECTSTATUS:
      console.log({ ...state, home_select_status: payload });
      return { ...state, home_select_status: payload };
    case SET_REPORTPAGE_STARTDATE:
      console.log({ ...state, report_startdate: payload });
      return { ...state, report_startdate: payload };
    case SET_REPORTPAGE_ENDDATE:
      console.log({ ...state, report_enddate: payload });
      return { ...state, report_enddate: payload };
    case SET_REPORTPAGE_STATUS:
      console.log({ ...state, report_status: payload });
      return { ...state, report_status: payload };
    case SET_REPORTPAGE_SELECTSTATUS:
      console.log({ ...state, report_select_status: payload });
      return { ...state, report_select_status: payload };
    default:
      return state;
  }
};

export default reducer;
