import Header from "../../components/Header";
import "./massdelivery.scss";
import React, { useEffect, useState } from "react";
import Services from "../../services/Services";
import { sources, shipmentStatuses } from "../../types/datas";
import { Modal, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";

function MassDelivery() {
  const poiID = localStorage.getItem("poi");
  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [points, setPoints] = useState([]);
  const [token, setToken] = useState("");
  const [orders, setOrders] = useState([]);
  const [willBeDelivered, setWillBeDelivered] = useState([]);
  const [sequenceNumber, setSequenceNumber] = useState("");
  const [sigName, setSigName] = useState("");
  const [sigLastname, setSigLastname] = useState("");
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");
  const [isBarcodeValid, setIsBarcodeValid] = useState(false);

  const [loadingList, setLoadingList] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setSequenceNumber("");
    setSigName("");
    setSigLastname("");
    setNote("");
  };

  const handleNamesChange = (e, name) => {
    const value = e.target.value;
    const newWord = value.replace(/[^a-zA-ZğĞüÜşŞıİöÖçÇ\s]/g, "");
    if (name === "name") {
      setSigName(newWord);
    } else {
      setSigLastname(newWord);
    }
  };

  const handleSigChange = (t) => {
    const allowedExtendedChars = [
      95, 58, 45, 32, 199, 46, 214, 220, 231, 246, 252, 351, 350, 287, 286, 304,
      305,
    ];
    let newTitle = "";
    for (let index = 0; index < t.length; index++) {
      if (
        (t.charCodeAt(index) >= 48 && t.charCodeAt(index) <= 57) ||
        (t.charCodeAt(index) >= 65 && t.charCodeAt(index) <= 90) ||
        allowedExtendedChars.includes(t.charCodeAt(index)) ||
        (t.charCodeAt(index) >= 97 && t.charCodeAt(index) <= 122)
      ) {
        newTitle = newTitle + t[index];
      }
    }
    setSequenceNumber(newTitle);
  };

  const handleSave = (id) => {
    setSearchValue("");
    setIsBarcodeValid(false);
    const selectedOrder = orders.find((order) => order.shipmentNo === id);
    if (selectedOrder) {
      const orderExists = willBeDelivered.some(
        (order) => order.shipmentNo === selectedOrder.shipmentNo
      );
      if (orderExists) {
        toast.warning("Aynı barkod numaraya sahip item zaten listenizde.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setWillBeDelivered((prevWillBeDelivered) => [
          ...prevWillBeDelivered,
          selectedOrder,
        ]);
      }
    } else {
      toast.warning("Girdiğiniz barkod'a ait sipariş bulunmamaktadır.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setShowOptions(false);
  };

  const getStatusLabel = (statusValue) => {
    const status = shipmentStatuses.find((item) => item.value === statusValue);
    return status ? status.label : "";
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (value >= 2) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
    setSearchValue(value);

    const filtered = orders.filter(
      (order) => order.shipmentNo && order.shipmentNo.includes(value)
    );
    console.log(filtered);
    const options = [];
    for (let i = 0; i < filtered.length; i++) {
      options.push(filtered[i].shipmentNo);
    }
    const twiceFilteredOptions = options.filter(
      (option) => !willBeDelivered.find((order) => order.shipmentNo === option)
    );
    setFilteredOptions(twiceFilteredOptions);

    const isBarcodeValid = twiceFilteredOptions.includes(value);
    setIsBarcodeValid(isBarcodeValid);
  };

  const deliver = () => {
    const type = "Signature";
    const value = `${sigName} ${sigLastname}, ${sequenceNumber}`;

    if (sigName != "" && sigLastname != "" && sequenceNumber != "") {
      setLoading(true);
      for (let i = 0; i < willBeDelivered.length; i++) {
        Services.ChangeStatus(token, willBeDelivered[i].id, type, value, note)
          .then((res) => {
            console.log(res.data);
            if (res.data.status) {
              toast.success("Teslimat Tamamlandı", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else {
              toast.error("Bir Hata Oluştu", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Güncellemede Bir Hata Oluştu", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          });
      }
      setLoading(false);
      setWillBeDelivered([]);
      closeModal();
    } else {
      toast.error("Tüm Boşlukları Doldurunuz.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  function fetchDatas() {
    if (willBeDelivered.length == 0) {
      setLoadingList(true);
      Services.GetToken().then((res) => {
        setToken(res.data.result.token);
        Services.GetLocations(res.data.result.token).then((result) => {
          setPoints(result.data.result);
        });
        Services.GetOrders(res.data.result.token, poiID, [5, 31]).then(
          (result) => {
            if (result.data.status == true) {
              setOrders(result.data.result);
            } else {
              console.log("Veri bulunamadı");
            }
            setLoadingList(false);
          }
        );
      });
    } else return;
  }

  useEffect(() => {
    fetchDatas();
  }, [willBeDelivered]);

  return (
    <div>
      <Header />
      <div className="mass-delivery">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <input
              type="text"
              value={searchValue}
              onChange={handleSearchChange}
              placeholder="Barkod Giriniz..."
            />
            {showOptions && (
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "white",
                  width: 300,
                  marginTop: 50,
                  border: "solid",
                  paddingBlock: 20,
                  borderWidth: 1,
                  borderRadius: 15,
                  display: "flex",
                  flexDirection: "column",
                  fontSize: 18,
                  justifyContent: "center",
                  textAlign: "center",
                  zIndex: 5,
                }}
              >
                {!loadingList ? (
                  <>
                    {" "}
                    {filteredOptions.map((option) => (
                      <span
                        onClick={() => {
                          setShowOptions(false);
                          setSearchValue(option);
                          setIsBarcodeValid(true);
                        }}
                        style={{ cursor: "pointer" }}
                        key={option}
                      >
                        {option}
                      </span>
                    ))}
                  </>
                ) : (
                  <text style={{ color: "gray", fontSize: 24 }}>
                    Gönderi listesi yükleniyor, lütfen bekleyiniz...
                  </text>
                )}
              </div>
            )}
          </div>
          <button
            style={{ display: isBarcodeValid ? "block" : "none" }}
            onClick={() => handleSave(searchValue)}
            className="button-71"
          >
            Kaydet
          </button>
        </div>
        <div style={{ flexDirection: "column", display: "flex" }}>
          {willBeDelivered.length > 0 && (
            <>
              <h5 style={{ textAlign: "center", marginTop: 150 }}>
                Teslim Edileceklerin Listesi
              </h5>
              {willBeDelivered.map((item, i) => {
                return (
                  <div key={i}>
                    <text>
                      {i + 1} - {item.title} {item.shipmentNo} ({" "}
                      {getStatusLabel(item.status)} )
                    </text>
                    <button
                      onClick={() => {
                        setWillBeDelivered((prevWillBeDelivered) =>
                          prevWillBeDelivered.filter((_, index) => index !== i)
                        );
                      }}
                      className="remove-button"
                    >
                      Kaldır
                    </button>
                  </div>
                );
              })}
              <button
                onClick={() => setShowModal(true)}
                style={{
                  backgroundColor: "orange",
                  width: 150,
                  justifyContent: "center",
                  display: "flex",
                  margin: "auto",
                  marginTop: 15,
                }}
                className="button-71"
              >
                İmza Teslim
              </button>

              <Modal show={showModal} onHide={closeModal} size="lg" centered>
                <Modal.Header closeButton>
                  <Modal.Title>Teslim Et</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <>
                    <div
                      style={{
                        marginBlock: 20,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        flexWrap: "wrap",
                      }}
                    >
                      <input
                        style={{
                          width: "100%",
                          maxWidth: 200,
                          paddingBlock: 3,
                          paddingLeft: 20,
                          borderRadius: 5,
                          border: "none",
                          boxShadow:
                            " rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                        }}
                        placeholder="İsim"
                        value={sigName}
                        onChange={(e) => handleNamesChange(e, "name")}
                      />
                      <input
                        style={{
                          width: "100%",
                          maxWidth: 200,
                          paddingBlock: 3,
                          paddingLeft: 20,
                          borderRadius: 5,
                          border: "none",
                          boxShadow:
                            " rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                        }}
                        placeholder="Soyisim"
                        value={sigLastname}
                        onChange={(e) => handleNamesChange(e, "lastname")}
                      />
                      <input
                        style={{
                          width: "100%",
                          maxWidth: 200,
                          paddingBlock: 3,
                          paddingLeft: 20,
                          borderRadius: 5,
                          border: "none",
                          boxShadow:
                            " rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                        }}
                        placeholder="İmza Sıra No"
                        value={sequenceNumber}
                        onChange={(e) => handleSigChange(e.target.value)}
                      />
                      <textarea
                        style={{
                          width: "89%",
                          minHeight: 60,
                          marginTop: 10,
                          padding: 10,
                          borderRadius: 5,
                          border: "none",
                          boxShadow:
                            "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                          resize: "vertical",
                        }}
                        placeholder="Notlar (isteğe bağlı)"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                    <button
                      onClick={() => deliver()}
                      style={{
                        display: "flex",
                        paddingBlock: 5,
                        paddingInline: 50,
                        borderRadius: 5,
                        border: "none",
                        backgroundColor: "#91c720",
                        color: "white",
                        fontWeight: "600",
                        margin: "auto",
                      }}
                    >
                      {loading ? (
                        <>
                          <ClipLoader
                            color={"white"}
                            loading={loading}
                            size={25}
                          />
                        </>
                      ) : (
                        "Tamamla"
                      )}
                    </button>
                  </>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={closeModal}>
                    Kapat
                  </Button>
                  {/* Additional button logic */}
                  {/* <Button variant="primary">Save changes</Button> */}
                </Modal.Footer>
              </Modal>
            </>
          )}
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}

export default MassDelivery;
