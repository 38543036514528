import Header from "../../components/Header";
import { Container, Col, Row, Modal, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./detailpage.scss";
import moment from "moment";
import { shipmentStatuses } from "../../types/datas";
import Services from "../../services/Services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

function DetailPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dateString = location.state.postDate;
  const deliverDateString = location.state.deliverDateTime;
  const [token, setToken] = useState("");
  const id = location.state.id;
  const [showModal, setShowModal] = useState(false);

  const [signature, setSignature] = useState(false);
  const [sequenceNumber, setSequenceNumber] = useState("");
  const [sigName, setSigName] = useState("");
  const [sigLastname, setSigLastname] = useState("");
  const [note, setNote] = useState("");

  const [SMS, setSMS] = useState(false);
  const [confirmSMS, setConfirmSMS] = useState(false);
  const [code, setCode] = useState("");
  const [sentCode, setSentCode] = useState("");
  const phone = location.state.phone;

  const [robot, setRobot] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [selectCustomer, setSelectCustomer] = useState(null);
  const [customerList, setCustomerList] = useState([]);

  const [newStatus, setNewStatus] = useState(location.state.status);
  const formattedDate = moment.unix(dateString).format("DD-MM-YYYY HH:mm");
  const formattedDeliverDate = moment
    .unix(deliverDateString)
    .format("DD-MM-YYYY HH:mm");

  const getStatusLabel = (statusValue) => {
    const status = shipmentStatuses.find((item) => item.value === statusValue);
    return status ? status.label : "";
  };

  const handleNamesChange = (e, name) => {
    const value = e.target.value;
    const newWord = value.replace(/[^a-zA-ZğĞüÜşŞıİöÖçÇ\s]/g, "");
    if (name === "name") {
      setSigName(newWord);
    } else {
      setSigLastname(newWord);
    }
  };

  const handleSigChange = (t) => {
    const allowedExtendedChars = [
      95, 58, 45, 32, 199, 46, 214, 220, 231, 246, 252, 351, 350, 287, 286, 304,
      305,
    ];
    let newTitle = "";
    for (let index = 0; index < t.length; index++) {
      if (
        (t.charCodeAt(index) >= 48 && t.charCodeAt(index) <= 57) ||
        (t.charCodeAt(index) >= 65 && t.charCodeAt(index) <= 90) ||
        allowedExtendedChars.includes(t.charCodeAt(index)) ||
        (t.charCodeAt(index) >= 97 && t.charCodeAt(index) <= 122)
      ) {
        newTitle = newTitle + t[index];
      }
    }
    setSequenceNumber(newTitle);
  };

  const openModal = (option) => {
    if (option === "SMS") {
      setSMS(true);
    } else if (option === "robot") {
      setRobot(true);
    } else {
      setSignature(true);
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSignature(false);
    setSMS(false);
    setCode("");
    setConfirmSMS(false);
    setNote("");
    setRobot(false);
    setSelectCustomer(null);
  };

  const sendingSMS = () => {
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    setSentCode(`${randomNumber}`);
    Services.SendSMS(phone, randomNumber, token).then((res) => {
      console.log("Kod Gönderildi:", res.data);
    });
  };

  const handleCustomerChange = (selectedOption) => {
    setCustomer(selectedOption.label);
    setSelectCustomer(selectedOption);
  };

  const createRobotOrder = () => {
    console.log(selectCustomer);
    const [addressID, phone] = selectCustomer.value.split("|");
    const senderName = selectCustomer.label.split(" - ")[0];
    Services.CreateRobotOrder(
      token,
      addressID,
      phone,
      senderName,
      location.state.id
    )
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          closeModal();
          // navigate("/homepage");
        } else {
          alert("Bir hata oluştu");
          closeModal();
        }
      })
      .catch((err) => {
        alert("Bir hata oluştu");
        closeModal();
        console.log(err);
      });
  };

  const deliver = () => {
    const type = SMS === true ? "Easypoint OTP" : "Signature";
    const value =
      SMS === true ? code : `${sigName} ${sigLastname}, ${sequenceNumber}`;
    if (SMS) {
      if (code === sentCode) {
        Services.ChangeStatus(token, id, type, value)
          .then((res) => {
            console.log(res.data);
            if (res.data.status) {
              setNewStatus("1");
              toast.success("Teslimat Tamamlandı", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              closeModal();
              navigate("/homepage");
            } else {
              toast.error("Bir Hata Oluştu", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Güncellemede Bir Hata Oluştu", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            closeModal();
          });
      } else {
        toast.error("Girilen Kod Değildir", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        closeModal();
      }
    } else {
      if (sigName != "" && sigLastname != "" && sequenceNumber != "") {
        Services.ChangeStatus(token, id, type, value, note)
          .then((res) => {
            console.log(res.data);
            if (res.data.status) {
              setNewStatus("1");
              toast.success("Teslimat Tamamlandı", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              closeModal();
              navigate("/homepage");
            } else {
              toast.error("Bir Hata Oluştu", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Güncellemede Bir Hata Oluştu", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            closeModal();
          });
      } else {
        toast.error("Tüm Boşlukları Doldurunuz.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    if (location.state.status === "5") {
      Services.GetRoboticCustomerList(tok).then((res) => {
        if (res.data.status) {
          const list = [];
          for (let i = 0; i < res.data.result.length; i++) {
            const label =
              res.data.result[i].customer_details.name +
              " " +
              res.data.result[i].customer_details.lastname +
              " - " +
              res.data.result[i].address_site +
              " Blok - Kat: " +
              res.data.result[i].address_floor +
              " - D: " +
              res.data.result[i].address_name;
            const value = `${res.data.result[i].address_uid}|${res.data.result[i].customer_details.phone}`;

            list.push({ label, value });
          }

          console.log(list);
          setCustomerList(list);
        }

        // Burada oluşturulan liste ile istediğiniz işlemi yapabilirsiniz
      });
    }
  }, []);

  return (
    <div className="detail">
      <Header />
      <Container style={{ marginBottom: 30 }}>
        <Row>
          <Col xs={12} sm={12} md={12}>
            <div className="paper">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {" "}
                <button
                  style={{
                    backgroundColor: "white",
                    color: "red",
                    borderRadius: 5,
                    borderColor: "red",
                    padding: 5,
                  }}
                  onClick={() => navigate(-1)}
                >
                  Geri Dön
                </button>
              </div>

              <h4 style={{ textAlign: "center", color: "#0052bd" }}>
                {location.state.title}
              </h4>
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {/* <div className="warning">
                  <div
                    style={{
                      height: 25,
                      width: 25,
                      backgroundColor: "#B62C2C",
                      borderRadius: 15,
                      marginRight: 10,
                    }}
                  />
                  <text>
                    Gönderi mağazadan mal kabule gönderilecek, porter atayınız.
                  </text>
                </div> */}
              </div>
              {/* Detail Contents */}
              <Col className="d-flex justify-content-between">
                <text style={{ fontSize: 16, fontWeight: "700" }}>ID:</text>
                <text>{location.state.id}</text>
              </Col>
              <div className="divider" />
              <Col className="d-flex justify-content-between">
                <text style={{ fontSize: 16, fontWeight: "700" }}>
                  Alıcı Kurumsal Üye:
                </text>
                <text>{location.state.corporationTitle}</text>
              </Col>
              <div className="divider" />
              <Col className="d-flex justify-content-between">
                <text style={{ fontSize: 16, fontWeight: "700" }}>
                  Kargo Türü:
                </text>
                <text>Paket</text>
              </Col>
              <div className="divider" />
              {location.state.takenName && (
                <>
                  <Col className="d-flex justify-content-between">
                    <text style={{ fontSize: 16, fontWeight: "700" }}>
                      Gönderen:
                    </text>
                    <text>{location.state.takenName}</text>
                  </Col>
                  <div className="divider" />
                </>
              )}
              {location.state.willTakenBy !== " " && (
                <>
                  {" "}
                  <Col className="d-flex justify-content-between">
                    <text style={{ fontSize: 16, fontWeight: "700" }}>
                      Alıcı:
                    </text>
                    <text>{location.state.willTakenBy}</text>
                  </Col>
                  <div className="divider" />
                </>
              )}
              {location.state.phone !== false && (
                <>
                  {" "}
                  <Col className="d-flex justify-content-between">
                    <text style={{ fontSize: 16, fontWeight: "700" }}>
                      Alıcı Telefon No:
                    </text>
                    <text>{location.state.phone}</text>
                  </Col>
                  <div className="divider" />
                </>
              )}

              <Col className="d-flex justify-content-between">
                <text style={{ fontSize: 16, fontWeight: "700" }}>
                  Teslim Edilecek İl:
                </text>
                <text>{location.state.city}</text>
              </Col>
              <div className="divider" />
              <Col className="d-flex justify-content-between">
                <text style={{ fontSize: 16, fontWeight: "700" }}>
                  Teslim Edilecek İlçe:
                </text>
                <text>{location.state.region}</text>
              </Col>
              <div className="divider" />
              <Col className="d-flex justify-content-between">
                <text style={{ fontSize: 16, fontWeight: "700" }}>
                  Teslim Edilecek Easy Point Noktası:
                </text>
                <text>{location.state.poi}</text>
              </Col>
              <div className="divider" />
              <Col className="d-flex justify-content-between">
                <text style={{ fontSize: 16, fontWeight: "700" }}>
                  Kargo Firması:
                </text>
                <text>{location.state.shipmentFirm}</text>
              </Col>
              <div className="divider" />
              <Col className="d-flex justify-content-between">
                <text style={{ fontSize: 16, fontWeight: "700" }}>
                  Kargo Takip No:
                </text>
                <text>{location.state.cargo}</text>
              </Col>
              <div className="divider" />
              <Col className="d-flex justify-content-between">
                <text style={{ fontSize: 16, fontWeight: "700" }}>
                  Gönderi Tarihi:
                </text>
                <text>{formattedDate}</text>
              </Col>
              <div className="divider" />
              {location.state.status === "1" && (
                <>
                  <Col className="d-flex justify-content-between">
                    <text style={{ fontSize: 16, fontWeight: "700" }}>
                      Teslim Tarihi:
                    </text>
                    <text>{formattedDeliverDate}</text>
                  </Col>
                  <div className="divider" />
                </>
              )}
              <Col className="d-flex justify-content-between">
                <text style={{ fontSize: 16, fontWeight: "700" }}>
                  Ödeme Durumu:
                </text>
                <text
                  style={{
                    backgroundColor:
                      location.state.payment === false
                        ? "#FF7373"
                        : "rgb(77 177 88)",
                    color: "white",
                    padding: 3,
                    borderRadius: 5,
                    paddingInline: 10,
                  }}
                >
                  {location.state.payment == false
                    ? "Ödeme Bekleniyor"
                    : "Ödendi"}
                </text>
              </Col>
              <div className="divider" />
              <Col className="d-flex justify-content-between">
                <text style={{ fontSize: 16, fontWeight: "700" }}>
                  Gönderi Durumu:
                </text>
                {newStatus === "5" || "31" ? (
                  <text
                    style={{
                      backgroundColor: "#73ABFF",
                      color: "white",
                      padding: 3,
                      borderRadius: 5,
                      paddingInline: 10,
                    }}
                  >
                    {getStatusLabel(location.state.status)}
                  </text>
                ) : (
                  <text
                    style={{
                      backgroundColor: "rgb(77 177 88)",
                      color: "white",
                      padding: 3,
                      borderRadius: 5,
                      paddingInline: 10,
                    }}
                  >
                    Tamamlandı
                  </text>
                )}
              </Col>

              {location.state.status !== "1" ? (
                <>
                  <div className="divider" />
                  <Col className="d-flex justify-content-between">
                    <text style={{ fontSize: 16, fontWeight: "700" }}>
                      Teslim Et
                    </text>
                    {location.state.status === "5" ? (
                      <div>
                        {phone.length > 5 && (
                          <button
                            style={{
                              backgroundColor: "orange",
                              marginRight: 15,
                            }}
                            className="button-71"
                            onClick={() => openModal("SMS")}
                          >
                            SMS
                          </button>
                        )}

                        <button
                          className="button-71"
                          style={{
                            marginRight: 15,
                          }}
                          onClick={() => openModal("signature")}
                        >
                          İMZA
                        </button>
                        <button
                          style={{ backgroundColor: "green" }}
                          className="button-71"
                          onClick={() => openModal("robot")}
                        >
                          Robota Sipariş Oluştur
                        </button>
                      </div>
                    ) : (
                      <button
                        className="button-71"
                        onClick={() => openModal("signature")}
                      >
                        İMZA
                      </button>
                    )}
                  </Col>
                </>
              ) : (
                <></>
              )}

              <Modal show={showModal} onHide={closeModal} size="lg" centered>
                <Modal.Header closeButton>
                  <Modal.Title>Teslim Et</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {SMS && (
                    <div
                      style={{
                        marginBlock: 20,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      {!confirmSMS && (
                        <>
                          <p style={{ textAlign: "center", marginTop: 20 }}>
                            Telefon Numarasına Kod Gönderilmesini Kabul Ediyor
                            Musunuz ?
                          </p>
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              style={{
                                paddingBlock: 5,
                                paddingInline: 50,
                                borderRadius: 5,
                                border: "none",
                                backgroundColor: "#91c720",
                                color: "white",
                                fontWeight: "600",
                                marginLeft: 15,
                              }}
                              onClick={() => {
                                sendingSMS();
                                setConfirmSMS(true);
                              }}
                            >
                              Evet
                            </button>
                            <button
                              style={{
                                paddingBlock: 5,
                                paddingInline: 50,
                                borderRadius: 5,
                                border: "none",
                                backgroundColor: "rgb(255, 115, 115)",
                                color: "white",
                                fontWeight: "600",
                                marginLeft: 15,
                              }}
                              onClick={closeModal}
                            >
                              Hayır
                            </button>
                          </div>
                        </>
                      )}

                      {confirmSMS && (
                        <>
                          <p style={{ textAlign: "center", marginTop: 20 }}>
                            Telefona Gelen Kodu Giriniz
                          </p>
                          <div
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <input
                              maxLength={4}
                              style={{
                                width: 100,
                                borderRadius: 5,
                                border: "none",
                                boxShadow:
                                  " rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                                textAlign: "center",
                              }}
                              onChange={(e) => setCode(e.target.value)}
                              value={code}
                            />
                            <button
                              onClick={() => deliver()}
                              style={{
                                paddingBlock: 5,
                                paddingInline: 50,
                                borderRadius: 5,
                                border: "none",
                                backgroundColor: "#91c720",
                                color: "white",
                                fontWeight: "600",
                                marginLeft: 15,
                              }}
                            >
                              Tamamla
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {signature && (
                    <>
                      <div
                        style={{
                          marginBlock: 20,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          flexWrap: "wrap",
                        }}
                      >
                        <input
                          style={{
                            width: "100%",
                            maxWidth: 200,
                            paddingBlock: 3,
                            paddingLeft: 20,
                            borderRadius: 5,
                            border: "none",
                            boxShadow:
                              " rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                          }}
                          placeholder="İsim"
                          value={sigName}
                          onChange={(e) => handleNamesChange(e, "name")}
                        />
                        <input
                          style={{
                            width: "100%",
                            maxWidth: 200,
                            paddingBlock: 3,
                            paddingLeft: 20,
                            borderRadius: 5,
                            border: "none",
                            boxShadow:
                              " rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                          }}
                          placeholder="Soyisim"
                          value={sigLastname}
                          onChange={(e) => handleNamesChange(e, "lastname")}
                        />
                        <input
                          style={{
                            width: "100%",
                            maxWidth: 200,
                            paddingBlock: 3,
                            paddingLeft: 20,
                            borderRadius: 5,
                            border: "none",
                            boxShadow:
                              " rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                          }}
                          placeholder="İmza Sıra No"
                          value={sequenceNumber}
                          onChange={(e) => handleSigChange(e.target.value)}
                        />
                        <textarea
                          style={{
                            width: "89%",
                            minHeight: 60,
                            marginTop: 10,
                            padding: 10,
                            borderRadius: 5,
                            border: "none",
                            boxShadow:
                              "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                            resize: "vertical",
                          }}
                          placeholder="Notlar (isteğe bağlı)"
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                        />
                      </div>

                      <button
                        onClick={() => deliver()}
                        style={{
                          display: "flex",
                          paddingBlock: 5,
                          paddingInline: 50,
                          borderRadius: 5,
                          border: "none",
                          backgroundColor: "#91c720",
                          color: "white",
                          fontWeight: "600",
                          margin: "auto",
                        }}
                      >
                        Tamamla
                      </button>
                    </>
                  )}
                  {robot && (
                    <div
                      style={{
                        marginBlock: 20,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <text>Müşteri seçiniz</text>
                      <Select
                        placeholder="Müşteri Seçiniz"
                        className="basic-single basic-single-long"
                        classNamePrefix="select"
                        name="color"
                        value={selectCustomer}
                        options={customerList}
                        onChange={handleCustomerChange}
                      />
                      <button
                        disabled={selectCustomer === null}
                        onClick={() => createRobotOrder()}
                        style={{
                          paddingBlock: 5,
                          paddingInline: 20,
                          borderRadius: 5,
                          border: "none",
                          backgroundColor: "#91c720",
                          color: "white",
                          fontWeight: "600",
                          marginTop: 20,
                        }}
                      >
                        Robota Sipariş Oluştur
                      </button>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={closeModal}>
                    Kapat
                  </Button>
                  {/* Additional button logic */}
                  {/* <Button variant="primary">Save changes</Button> */}
                </Modal.Footer>
              </Modal>
            </div>
          </Col>
          {/* <Col xs={8} sm={12} md={5}>
            <div className="paper">
              <h5 style={{ textAlign: "center", color: "#0052bd" }}>
                Gönderi Logları
              </h5>
              <Row className="justify-content-center">
                <div className="d-flex justify-content-end mb-2">
                  08.02.2022 - 15:35
                </div>
                <div
                  style={{
                    backgroundColor: "#FFEFD1",
                    padding: 10,
                    width: "95%",
                    textAlign: "center",
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  Gönderinin [5464654656546] adlı taşıma görevlisi tarafından
                  teslim alındığı bilgisi Getir’e bildirilirken bir sorun
                  oluştu.
                </div>
              </Row>
              <div className="divider" />
              <Row className="justify-content-center">
                <div className="d-flex justify-content-end mb-2">
                  08.02.2022 - 15:35
                </div>
                <div
                  style={{
                    backgroundColor: "#FFEFD1",
                    padding: 10,
                    width: "95%",
                    textAlign: "center",
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  Gönderinin [5464654656546] adlı taşıma görevlisi tarafından
                  teslim alındığı bilgisi Getir’e bildirilirken bir sorun
                  oluştu.
                </div>
              </Row>
              <div className="divider" />
              <Row className="justify-content-center">
                <div className="d-flex justify-content-end mb-2">
                  08.02.2022 - 15:35
                </div>
                <div
                  style={{
                    backgroundColor: "#FFEFD1",
                    padding: 10,
                    width: "95%",
                    textAlign: "center",
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  Gönderinin [5464654656546] adlı taşıma görevlisi tarafından
                  teslim alındığı bilgisi Getir’e bildirilirken bir sorun
                  oluştu.
                </div>
              </Row>
              <div className="divider" />
              <Row className="justify-content-center">
                <div className="d-flex justify-content-end mb-2">
                  08.02.2022 - 15:35
                </div>
                <div
                  style={{
                    backgroundColor: "#FFEFD1",
                    padding: 10,
                    width: "95%",
                    textAlign: "center",
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  Gönderinin [5464654656546] adlı taşıma görevlisi tarafından
                  teslim alındığı bilgisi Getir’e bildirilirken bir sorun
                  oluştu.
                </div>
              </Row>
              <div className="divider" />
              <Row className="justify-content-center">
                <div className="d-flex justify-content-end mb-2">
                  08.02.2022 - 15:35
                </div>
                <div
                  style={{
                    backgroundColor: "#FFEFD1",
                    padding: 10,
                    width: "95%",
                    textAlign: "center",
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  Gönderinin [5464654656546] adlı taşıma görevlisi tarafından
                  teslim alındığı bilgisi Getir’e bildirilirken bir sorun
                  oluştu.
                </div>
              </Row>
              <div className="divider" />
              <Row className="justify-content-center">
                <div className="d-flex justify-content-end mb-2">
                  08.02.2022 - 15:35
                </div>
                <div
                  style={{
                    backgroundColor: "#FFEFD1",
                    padding: 10,
                    width: "95%",
                    textAlign: "center",
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  Gönderinin [5464654656546] adlı taşıma görevlisi tarafından
                  teslim alındığı bilgisi Getir’e bildirilirken bir sorun
                  oluştu.
                </div>
              </Row>
              <div className="divider" />
              <Row className="justify-content-center">
                <div className="d-flex justify-content-end mb-2">
                  08.02.2022 - 15:35
                </div>
                <div
                  style={{
                    backgroundColor: "#FFEFD1",
                    padding: 10,
                    width: "95%",
                    textAlign: "center",
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  Gönderinin [5464654656546] adlı taşıma görevlisi tarafından
                  teslim alındığı bilgisi Getir’e bildirilirken bir sorun
                  oluştu.
                </div>
              </Row>
            </div>
          </Col> */}
        </Row>
        <button
          style={{
            backgroundColor: "white",
            color: "red",
            borderRadius: 5,
            borderColor: "red",
            padding: 5,
          }}
          onClick={() => navigate(-1)}
        >
          Geri Dön
        </button>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default DetailPage;
