import Header from "../../components/Header";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { shipmentFirms } from "../../types/datas";
import "./massentrypage.scss";
import Services from "../../services/Services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";

function DeliveryFromCargo() {
  const navigate = useNavigate();
  const createdByUser = localStorage.getItem("loginID");
  const mallID = localStorage.getItem("mallID");
  const poi = localStorage.getItem("poi");
  const [token, setToken] = useState("");
  const [shipmentFirm, setShipmentFirm] = useState(null);
  const [selectShipmentFirm, setSelectShipmentFirm] = useState(null);
  const [corporationalList, setCorporationalList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState([
    {
      id: 1,
      name: "Form 1",
      formData: {
        uID: "",
        numberOfPackages: 1,
        poiID: `${poi}`,
        direction: "InBranch",
        shipmentNo: "",
        deliveryCode: "",
        postType: "Paket",
        corporation: "",
        takenName: "",
        createdBy: `${createdByUser}`,
        manuelEntry: false,
      },
    },
  ]);

  const handleShipmentFirmChange = (selectedOption) => {
    setShipmentFirm(selectedOption.label);
    setSelectShipmentFirm(selectedOption);
    setForms((prevForms) => {
      const updatedForms = [...prevForms];

      updatedForms.forEach((form) => {
        if (form.formData.shipmentFirm === null) {
          form.formData.shipmentFirm = selectedOption.label;
        }
      });

      return updatedForms;
    });
  };

  const handleCheckBoxChange = (e, formId) => {
    const { checked } = e.target;
    setForms((prevForms) => {
      const updatedForms = [...prevForms];
      const formIndex = updatedForms.findIndex((form) => form.id === formId);

      if (formIndex !== -1) {
        updatedForms[formIndex].formData.manuelEntry = checked;
      }

      return updatedForms;
    });
  };

  const handleCount = (value, name, formId) => {
    let newValue = value;
    if (name === "decrease") {
      newValue = value - 1;
    } else {
      newValue = value + 1;
    }
    const e = {
      target: {
        value: newValue,
        name: "numberOfPackages",
      },
    };
    handleInputChange(e, formId);
  };

  const handleInputChange = (e, formId) => {
    const { name, value } = e.target || e;
    setForms((prevForms) => {
      const updatedForms = [...prevForms];
      const formIndex = updatedForms.findIndex((form) => form.id === formId);

      if (formIndex !== -1) {
        updatedForms[formIndex].formData[name] = value;
        updatedForms[formIndex].formData.shipmentFirm = shipmentFirm;

        if (name === "shipmentNo") {
          updatedForms[formIndex].formData.deliveryCode = value;
        } else if (name === "manuelPhone") {
          updatedForms[formIndex].formData.manuelPhone = updatedForms[
            formIndex
          ].formData.manuelPhone.replace(/\D/g, "");
        } else if (name === "numberOfPackages") {
          updatedForms[formIndex].formData.numberOfPackages = value;
        } else if (e.label) {
          updatedForms[formIndex].formData.uID = value;
          updatedForms[formIndex].formData.corporation = e;
          if (e.label.toLowerCase().includes("muhaberat")) {
            // e.label içinde "muhaberat" alt elemanı var
            // Burada istediğiniz işlemleri gerçekleştirebilirsiniz
            updatedForms[formIndex].formData.manuelEntry = true;
          } else {
            updatedForms[formIndex].formData.manuelEntry = false;
          }
        }

        if (updatedForms[formIndex].formData.manuelEntry) {
          // Manuel giriş işaretlendiğinde customer objesini oluştur
          updatedForms[formIndex].formData.customer = {
            name: updatedForms[formIndex].formData.manuelName || "",
            lastname: updatedForms[formIndex].formData.manuelLastname || "",
            phone:
              updatedForms[formIndex].formData.manuelPhone?.replace(
                /\D/g,
                ""
              ) || "",
          };
        } else {
          // Manuel giriş iptal edildiğinde customer objesini kaldır
          delete updatedForms[formIndex].formData.customer;
        }
      }

      return updatedForms;
    });
  };

  const handleSubmit = () => {
    if (forms.length != 0 && shipmentFirm != null) {
      setLoading(true);
      const formDataArray = forms.map((form) => {
        const { corporation, ...formData } = form.formData;
        if (formData.manuelEntry) {
          if (
            formData.uID === "" ||
            formData.shipmentNo === "" ||
            formData.manuelName === "" ||
            formData.manuelLastname === ""
          ) {
            setLoading(false);
            toast.error("Lütfen zorunlu alanları doldurunuz", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            return null;
          }
        } else {
          if (
            form.formData.uID === "" ||
            form.formData.shipmentNo === "" ||
            form.formData.manuelName === "" ||
            form.formData.manuelLastname === ""
          ) {
            setLoading(false);
            toast.error("Lütfen zorunlu alanları doldurunuz", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            return null;
          }
        }
        return formData;
      });

      if (formDataArray.includes(null)) {
        setLoading(false);
        return;
      }
      setLoading(false); //daha sonra kaldırılacak
      console.log("formDataArray:", formDataArray);
      // const filteredFormDataArray = formDataArray.map((form)=> {
      //   const { manuelName,manuelLastname, ...formData } = form.formData;
      // })

      Services.CreateOrder(token, formDataArray)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          toast.success("Toplu Kargo Alımı Tamamlandı", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setShipmentFirm(null);
          setSelectShipmentFirm(null);
          setForms([
            {
              id: 1,
              name: "Form 1",
              formData: {
                uID: "",
                numberOfPackages: 1,
                poiID: `${poi}`,
                direction: "InBranch",
                shipmentNo: "",
                deliveryCode: "",
                postType: "Paket",
                corporation: "",
                takenName: "",
                manuelEntry: false,
                createdBy: `${createdByUser}`,
              },
            },
          ]);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          toast.error("Bir Hata Oluştu", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      setLoading(false);
      toast.error("Bilgileri Doldurunuz", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
  };

  const addRow = () => {
    const newFormId = forms.length + 1;
    const newFormName = `Form ${newFormId}`;

    const newForm = {
      id: newFormId,
      name: newFormName,
      formData: {
        uID: "",
        numberOfPackages: 1,
        poiID: `${poi}`,
        direction: "InBranch",
        shipmentNo: "",
        deliveryCode: "",
        postType: "Paket",
        corporation: "",
        takenName: "",
        manuelEntry: false,
        createdBy: `${createdByUser}`,
      },
    };

    setForms([...forms, newForm]);
  };

  const deleteRow = (formId) => {
    setForms((prevForms) => prevForms.filter((form) => form.id !== formId));
  };

  function fetchDatas() {
    Services.GetToken().then((res) => {
      localStorage.setItem("token", res.data.result.token);
      setToken(res.data.result.token);
      Services.GetCorporationList(res.data.result.token, mallID)
        .then((result) => {
          const updatedCorporationalList = result.data.result.map((item) => ({
            value: item.id,
            label: item.title,
          }));
          setCorporationalList(updatedCorporationalList);
        })
        .catch((err) => console.log(err));
    });
  }

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <div className="mass-entry">
      <Header />
      <div className="center">
        <div
          style={{
            justifyContent: "flex-start",
            display: "flex",
            width: "85%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              marginBottom: 50,
            }}
          >
            <button
              style={{
                marginRight: 20,
                backgroundColor: "transparent",
                color: "#b91010",
                borderRadius: 5,
                borderColor: "#ff6b6b",
              }}
              onClick={() => navigate("/mass-entry-delivery-from-cargo")}
            >
              Kargodan Teslim Alım
            </button>
            <button
              style={{
                backgroundColor: "#ff6b6b",
                color: "white",
                borderRadius: 5,
                borderColor: "#ff6b6b",
              }}
              onClick={() => navigate("/mass-entry-delivery-from-porter")}
            >
              Porterden Teslim Alım
            </button>
          </div>
          <h5 style={{ textAlign: "center", marginBottom: 50 }}>
            Kargodan Teslim Alım
          </h5>
          <text>Lütfen Kargo Firmasını Seçin*</text>
          <Select
            placeholder="Kargo Firması"
            className="basic-single"
            classNamePrefix="select"
            name="color"
            value={selectShipmentFirm}
            options={shipmentFirms}
            onChange={handleShipmentFirmChange}
          />
        </div>
      </div>
      <div className="center">
        <div
          style={{
            width: "100%",
            display: "flex",
            width: "85%",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
          className="form-content"
        >
          {forms.map((form) => (
            <div>
              <div
                className="form-content"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBlock: 30,
                }}
                key={form.id}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <text>Kargo Takip No *</text>
                  <input
                    type="text"
                    name="shipmentNo"
                    placeholder={`Kargo Takip No`}
                    value={form.formData.shipmentNo}
                    onChange={(e) => handleInputChange(e, form.id)}
                  />
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <text>Müşteri *</text>
                  <Select
                    placeholder="Müşteri*"
                    className="basic-single"
                    classNamePrefix="select"
                    name="color"
                    value={form.formData.corporation}
                    options={corporationalList}
                    onChange={(e) => handleInputChange(e, form.id)}
                  />
                  {/* <select
                    name="corporation"
                    placeholder={`Müşteri`}
                    value={form.formData.corporation}
                    onChange={(e) => handleInputChange(e, form.id)}
                  >
                    <option value={""}>{""}</option>
                    {corporationalList.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.title}
                        </option>
                      );
                    })}
                  </select> */}
                </div>

                {/* <div style={{ display: "flex", flexDirection: "column" }}>
                <text>Kargonuzun Türü *</text>
                <input
                  type="text"
                  name="postType"
                  placeholder={`Kargonuzun Türü`}
                  value={form.formData.postType}
                  onChange={(e) => handleInputChange(e, form.id)}
                />
              </div> */}

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <text>Gönderen Kişi/Firma Adı</text>
                  <input
                    type="text"
                    name="takenName"
                    placeholder={`Gönderen Kişi/Firma Adı`}
                    value={form.formData.takenName}
                    onChange={(e) => handleInputChange(e, form.id)}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <text>Kargo Adedi</text>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <button
                      onClick={() =>
                        handleCount(
                          form.formData.numberOfPackages,
                          "decrease",
                          form.id
                        )
                      }
                      disabled={form.formData.numberOfPackages === 1}
                      style={{
                        marginRight: 10,
                        borderRadius: 5,
                        display: "flex",
                        fontSize: 20,
                        borderWidth: 0.9,
                        paddingInline: 8,
                        paddingTop: 0,
                        marginTop: 10,
                        borderColor: "#dbdbdb",
                        background:
                          "linear-gradient(to bottom, #ffffff, #f6f6f6)",
                      }}
                    >
                      -
                    </button>
                    <input
                      type="text"
                      name="numberOfPackages"
                      placeholder="Kargo Adedi"
                      value={form.formData.numberOfPackages}
                      readOnly
                      style={{ width: 40, paddingLeft: 15 }}
                    />
                    <button
                      onClick={() =>
                        handleCount(
                          form.formData.numberOfPackages,
                          "increase",
                          form.id
                        )
                      }
                      disabled={form.formData.numberOfPackages === 9}
                      style={{
                        marginLeft: 10,
                        borderRadius: 5,
                        display: "flex",
                        fontSize: 20,
                        borderWidth: 0.9,
                        paddingTop: 0,
                        marginTop: 10,
                        borderColor: "#dbdbdb",
                        background:
                          "linear-gradient(to bottom, #ffffff, #f6f6f6)",
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
                <text
                  className="button-71"
                  style={{
                    backgroundColor: "rgb(245, 97, 97)",
                    height: 34,
                    paddingInline: 15,
                    alignItems: "center",
                    display: "flex",
                    color: "white",
                    borderRadius: 5,
                    fontWeight: "600",
                    marginTop: 30,
                  }}
                  onClick={() => deleteRow(form.id)}
                >
                  Satırı Sil
                </text>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginTop: 50,
                }}
              >
                {form.formData.manuelEntry && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <text>İsim*</text>
                      <input
                        type="text"
                        name="manuelName"
                        placeholder={`İsim`}
                        value={form.formData.manuelName}
                        onChange={(e) => handleInputChange(e, form.id)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: 40,
                      }}
                    >
                      <text>Soyisim*</text>
                      <input
                        type="text"
                        name="manuelLastname"
                        placeholder={`Soyisim`}
                        value={form.formData.manuelLastname}
                        onChange={(e) => handleInputChange(e, form.id)}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: 40,
                      }}
                    >
                      <text>Telefon</text>
                      <input
                        type="text"
                        name="manuelPhone"
                        placeholder={`05(XXXXXXXXX)`}
                        maxLength={11}
                        value={form.formData.manuelPhone}
                        onChange={(e) => handleInputChange(e, form.id)}
                      />
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#c3c3c3",
                  height: 1,
                  marginTop: 50,
                }}
              />
            </div>
          ))}
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              paddingTop: 30,
              paddingBottom: 100,
            }}
          >
            <button className="button-71 me-5" onClick={addRow}>
              Satır Ekle
            </button>
            <button
              style={{ backgroundColor: "green" }}
              className="button-71 "
              onClick={handleSubmit}
            >
              {loading ? (
                <>
                  <ClipLoader color={"white"} loading={loading} size={25} />
                </>
              ) : (
                "Gönder"
              )}
            </button>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryFromCargo;
