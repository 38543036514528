export const sources = [
  { label: "-", value: 0 },
  { label: "Trendyol", value: 1 },
  { label: "Hepsiburada", value: 2 },
  { label: "Getir", value: 3 },
  { label: "Eren Holding", value: 4 },
  { label: "Easypoint App", value: 5 },
];

export const shipmentStatuses = [
  { label: "-", value: "0" },
  { label: "Bekleniyor", value: "3" },
  { label: "Concierge Kuryeye Teslim Edildi", value: "10" },
  { label: "Çoklu gönderim başladı", value: "15" },
  { label: "Depoda Teslim Alınmayı Bekliyor", value: "11" },
  { label: "Getir Kuryesine Teslim Edildi", value: "17" }, // 1 olarak gönderme ihtimalim var
  { label: "Hepsiburadaya İade Edildi", value: "21" },
  { label: "İade Edilecek", value: "9" },
  { label: "İptal Edildi, Mağaza Geri Götürülüyor", value: "19" },
  { label: "Mağazada Teslim Alınmayı Bekliyor", value: "12" },
  { label: "Mal Kabulde Teslim Edilmek Üzere Bekliyor", value: "14" },
  { label: "Robot Üzerinden İptal", value: "??" },
  { label: "Robota Teslim Edilecek", value: "???" },
  { label: "Şubede", value: "5" },
  { label: "Tamamlandı", value: "1" },
  { label: "Taşıma Görevlisi Atandı", value: "8" },
  { label: "Taşıma Görevlisi Zimmetinde", value: "4" },
  { label: "Trendyola İade Edildi", value: "16" },
  { label: "Muhaberattan Kargoya Verilecek", value: "31" },
  { label: "Robottan Onay Bekleniyor", value: "26" },
  { label: "Robota Teslim Edilecek", value: "27" },
  { label: "Robot Üzerinde", value: "28" },
  { label: "Robot Üzerinden İptal", value: "29" },
];

export const shipmentFirms = [
  { label: "Yurtiçi Kargo", value: "1" },
  { label: "Aras Kargo", value: "2" },
  { label: "MNG Kargo", value: "3" },
  { label: "Sürat Kargo", value: "4" },
  { label: "PTT Kargo", value: "5" },
  { label: "UPS", value: "6" },
  { label: "GKN Kargo", value: "7" },
  { label: "Ankara Kargo", value: "8" },
  { label: "Diğer", value: "9" },
];
