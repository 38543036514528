import Header from "../../components/Header";
import Services from "../../services/Services";
import "./reportpage.scss";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";

const shipmentStatuses = [
  { label: "Tümü", value: "100" },
  { label: "Tamamlanandı", value: "1" },
  { label: "Müşteriye Teslim Edilmek Üzere Şubede", value: "5" },
  { label: "Kargoya Teslim Edilmek Üzere Şubede", value: "31" },
];

function ReportPage() {
  const navigate = useNavigate();
  const mallID = localStorage.getItem("mallID");
  const poiID = localStorage.getItem("poi");
  const [token, setToken] = useState("");
  const [orders, setOrders] = useState([]);
  const [points, setPoints] = useState([]);
  const [selectShippingStatus, setSelectShippingStatus] = useState(
    shipmentStatuses[0]
  );
  const [loading, setLoading] = useState(false);
  const [corporationalList, setCorporationalList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [stasues, setStasuses] = useState([5, 1, 31]);
  const tableRef = useRef(null);
  const [isEmpty, setIsEmpty] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const getStatusLabel = (statusValue) => {
    const status = shipmentStatuses.find((item) => item.value === statusValue);
    return status ? status.label : "";
  };

  const getTitleById = (id) => {
    const item = points.find((item) => item.id === id);
    return item ? item.title : "";
  };

  const formatDate = (date) => {
    const formattedDate = moment.unix(date).format("DD.MM.YYYY HH:mm");
    return formattedDate;
  };

  const getNameById = (id) => {
    const name = corporationalList.find((item) => item.id === id);
    return name ? name.title : "";
  };

  const handleShipmentChange = (selectedOption) => {
    setSelectShippingStatus(selectedOption);
    if (selectedOption.value === "100") {
      setStasuses([5, 1, 31]);
    } else if (selectedOption.value === "1") {
      setStasuses([1]);
    } else if (selectedOption.value === "5") {
      setStasuses([5]);
    } else {
      setStasuses([31]);
    }
  };

  const handleClearClick = () => {
    setSelectShippingStatus(shipmentStatuses[0]);
    setStasuses([5, 31, 1]);
    setStartDate(new Date());
    setEndDate(new Date());
    setTrigger(!trigger);
  };

  const handleExportExcel = () => {
    const title = `Rapor - ${moment().format("DD/MM/YYYY HH:mm")}`;
    const dataToExport = orders.map((item, i) => {
      return {
        id: i + 1,
        shipmentNo: `${item.shipmentNo}`,
        name: getNameById(item.uID),
        pointName: getTitleById(item.sentToEasyPoint),
        status: getStatusLabel(item.status),
        createdDate: formatDate(item.createdDateTime),
      };
    });
    if (!isEmpty) {
      Services.ExportToExcel(token, dataToExport, title)
        .then((res) => {
          console.log(res.data);
          if (res.data.status) {
            window.open(`${res.data.result}`, "_blank");
          }
        })
        .catch((err) => console.log(err));
    }
    console.log(dataToExport);
  };

  function fetchDatas() {
    setLoading(true);
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
    console.log("fetch içindeki statuses", stasues);
    Services.GetToken().then((res) => {
      localStorage.setItem("token", res.data.result.token);
      setToken(res.data.result.token);
      Services.GetLocations(res.data.result.token).then((result) => {
        setPoints(result.data.result);
      });
      Services.GetFilteredOrders(
        res.data.result.token,
        poiID,
        stasues,
        formattedStartDate,
        formattedEndDate
      ).then((result) => {
        if (result.data.status == true) {
          setOrders(result.data.result);
          console.log("Güncel data:", result.data.result);
          setIsEmpty(false);
          setLoading(false);
        } else {
          setIsEmpty(true);
          setLoading(false);
        }
      });
      Services.GetCorporationList(res.data.result.token, mallID)
        .then((result) => {
          setCorporationalList(result.data.result);
        })
        .catch((err) => console.log(err));
    });
  }

  useEffect(() => {
    fetchDatas();
  }, [trigger]);

  return (
    <div className="app">
      <Header />
      <div className="center">
        {loading ? (
          <div style={{ display: "flex", marginTop: 50 }}>
            <SyncLoader color={"blue"} loading={loading} size={10} />
          </div>
        ) : (
          <div
            style={{
              flexDirection: "column",
              overflowX: "auto",
              minHeight: 700,
            }}
          >
            <div
              className="report"
              style={{
                display: "flex",
                marginBottom: 15,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ flexDirection: "row" }}>
                <div className="filter">
                  <text style={{ fontWeight: 600 }}>Başlangıç Tarihi</text>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    maxDate={endDate}
                    dateFormat="dd.MM.yyyy"
                    placeholderText="Başlangıç Tarihi"
                    locale={tr}
                    className="date-picker"
                  />
                </div>
              </div>
              <div style={{ flexDirection: "row" }}>
                <div className="filter">
                  <text style={{ fontWeight: 600 }}>Bitiş Tarihi</text>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsStart
                    minDate={startDate}
                    dateFormat="dd.MM.yyyy"
                    placeholderText="Bitiş Tarihi"
                    locale={tr}
                    className="date-picker"
                  />
                </div>
              </div>
              <div style={{ flexDirection: "row" }}>
                <div className="filter">
                  <text style={{ fontWeight: 600 }}>Kargo Durumu</text>
                  <Select
                    placeholder="Kargo Durumu"
                    className="basic-single"
                    classNamePrefix="select"
                    name="color"
                    defaultValue={shipmentStatuses}
                    value={selectShippingStatus}
                    options={shipmentStatuses}
                    onChange={handleShipmentChange}
                  />
                </div>
              </div>
              <div style={{ height: 0, paddingLeft: 25 }}>
                <Button
                  style={{
                    width: 120,
                    backgroundColor: "#3677d7",
                    border: "none",
                  }}
                  onClick={() => fetchDatas()}
                >
                  <text style={{ fontWeight: "600" }}>Filtrele</text>
                </Button>
              </div>

              <div style={{ height: 0 }}>
                <Button
                  style={{
                    width: 120,
                    backgroundColor: "#f56161",
                    border: "none",
                  }}
                  onClick={() => handleClearClick()}
                >
                  <text style={{ fontWeight: "600" }}>Temizle</text>
                </Button>
              </div>
              <div style={{ height: 0 }}>
                <Button
                  style={{
                    width: 120,
                    backgroundColor: "rgb(54 141 68",
                    border: "none",
                  }}
                  onClick={handleExportExcel}
                >
                  <text style={{ fontWeight: "600" }}>Excel'e Aktar</text>
                </Button>
              </div>
            </div>

            <div className="table-container">
              <table ref={tableRef}>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Kargo Takip No</th>
                    <th>Firma/Kişi</th>
                    <th>Nokta Adı</th>
                    <th>Kargo Durumu</th>
                    <th>Oluşturulma Tarihi</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  {isEmpty ? (
                    <tr>
                      <td colSpan="7" style={{ textAlign: "center" }}>
                        <h2>Seçili tarih aralıklarında veri bulunmamaktadır</h2>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {orders.map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item.shipmentNo}</td>
                          <td>{getNameById(item.uID)}</td>
                          <td>{getTitleById(item.sentToEasyPoint)}</td>
                          <td>{getStatusLabel(item.status)}</td>
                          <td>{formatDate(item.createdDateTime)}</td>
                          <td>
                            <FaEye
                              onClick={() => {
                                navigate("/detail", {
                                  state: {
                                    title: item.title,
                                    id: item.id,
                                    corporationTitle: getNameById(item.uID),
                                    postType: item.postType,
                                    takenName: item.takenName,
                                    willTakenBy: item.willTakenBy,
                                    phone: item.phone,
                                    poi: getTitleById(item.sentToEasyPoint),
                                    shipmentFirm: item.shipmentFirm,
                                    city: item.sentToCity,
                                    region: item.sentToRegion,
                                    cargo: item.shipmentNo,
                                    postDate: item.createdDateTime,
                                    deliverDateTime: item.deliverDateTime,
                                    payment: item.payment,
                                    status: item.status,
                                  },
                                });
                              }}
                              style={{
                                color: "black",
                                fontSize: "22px",
                                marginLeft: 7,
                                cursor: "pointer",
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReportPage;
