// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  align-items: center;
  justify-content: center;
}
.center {
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* max-width: 1300px; */
  /* min-width: 500px; */
}

@media screen and (max-width: 800px) {
  .center {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,wBAAwB;EACxB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".app {\n  align-items: center;\n  justify-content: center;\n}\n.center {\n  /* text-align: center; */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: auto;\n  /* max-width: 1300px; */\n  /* min-width: 500px; */\n}\n\n@media screen and (max-width: 800px) {\n  .center {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
