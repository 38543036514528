// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-link {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding-right: 20px;
  color: white !important;
}

@media screen and (max-width: 992px) {
  .header-link {
    margin-bottom: 5px;
  }
}
.navbar {
  background-color: rgb(26, 28, 39) !important;
  --bs-navbar-padding-y: 0rem;
  margin-bottom: 60px;
}

.container-fluid {
  margin-block: 0px !important;
}

.navbar-toggler {
  color: white !important;
  background-color: white;
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
}`, "",{"version":3,"sources":["webpack://./src/components/header.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE;IACE,kBAAA;EACF;AACF;AACA;EACE,4CAAA;EACA,2BAAA;EACA,mBAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AAEA;EACE,uBAAA;EACA,uBAAA;EACA,8EAAA;AACF","sourcesContent":[".header-link {\r\n  text-decoration: none;\r\n  font-size: 16px;\r\n  font-weight: 500;\r\n  padding-right: 20px;\r\n  color: white !important;\r\n}\r\n\r\n@media screen and (max-width: 992px) {\r\n  .header-link {\r\n    margin-bottom: 5px;\r\n  }\r\n}\r\n.navbar {\r\n  background-color: rgb(26, 28, 39) !important;\r\n  --bs-navbar-padding-y: 0rem;\r\n  margin-bottom: 60px;\r\n}\r\n\r\n.container-fluid {\r\n  margin-block: 0px !important;\r\n}\r\n\r\n.navbar-toggler {\r\n  color: white !important;\r\n  background-color: white;\r\n  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
